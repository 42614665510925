import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="student-subscriptions"
export default class extends Controller {
  static targets = ["centerSelect", "courseSelect"]

  connect() {
    this.retrieveSelectForCourses(this.centerSelectTarget.value)
  }

  centerChange(event) {
    this.retrieveSelectForCourses(this.centerSelectTarget.value)
  }

  retrieveSelectForCourses(centerId) {
    fetch(`/update_courses/${centerId ? centerId : "null"}`)
      .then(response => response.text())
      .then((html) => {
        this.courseSelectTarget.innerHTML = html;
      })
  }
}
