// const SUBS_DOUBLE_CONFIRM = () => {
//   const confirm1 = 
//   if(confirm1) {
//     return confirm("¿CONFIRMAR LA ELIMINACIÓN DE LAS SUBSCRIPCIONES? ESTA ACCIÓN NO PUEDE REVERTIRSE.")
//   } else {
//     return false;
//   }
// }

import { Controller } from "@hotwired/stimulus"

const CONFIRM_1_TEXT = "¿Estás seguro de querer eliminar todas las subscripciones? Esta acción solo debe de realizarse al final del año lectivo."
const CONFIRM_2_TEXT = "¿CONFIRMAR LA ELIMINACIÓN DE LAS SUBSCRIPCIONES? ESTA ACCIÓN NO PUEDE REVERTIRSE."

export default class extends Controller {
  delete(event) {
    let confirm1 = confirm(CONFIRM_1_TEXT)
    if(!confirm1) {
      event.preventDefault()
      event.stopImmediatePropagation()
      return;
    } else {
      let confirm2 = confirm(CONFIRM_2_TEXT)
      if(!confirm2) {
        event.preventDefault()
        event.stopImmediatePropagation()
      }
    }
  }
}
